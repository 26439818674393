import React, { Component } from 'react';
import { ISuggestionLink } from '../searchresultpage.d';
import styles from './suggestion-link.scss';
import {
    PageViewLogDataId,
    SearchTelemetryAjaxCaller,
    SearchTelemetryData,
} from '../SearchTelemetryAjaxCaller';

export default class SuggestionLinks extends Component<{
    suggestionLink: ISuggestionLink;
    magicLink: string;
}> {
    constructor(props) {
        super(props);
    }

    public render() {
        const stm = this.props.suggestionLink.telemetryDataViewModel
            ? this.props.suggestionLink.telemetryDataViewModel.stm
            : null;
        return (
            <div className={styles.suggestion_link_wrapper}>
                <a
                    className={styles.suggestion_link}
                    href={this.props.suggestionLink.target}
                    data-stm={stm}
                    onClick={() => this.handleTelemetry(stm)}
                >
                    {this.props.suggestionLink.searchTerm.toLowerCase()}
                </a>
            </div>
        );
    }

    private handleTelemetry(stm: string): void {
        if (!stm) return;
        const data = new SearchTelemetryData(
            stm,
            PageViewLogDataId.getInstance().get(),
            this.props.suggestionLink.portal,
            this.props.suggestionLink.culture,
            this.props.magicLink
        );
        SearchTelemetryAjaxCaller.postData(data);
    }
}
