import React, { Component } from 'react';
import ArrowDownIcn from '../../../Assets/svg/arrow_down';
import classNames from 'classnames';
import styles from './suggestion-links.scss';
import { ISuggestionLinks, ISuggestionLinksState } from '../searchresultpage.d';
import SuggestionLink from './suggestion-link';

export default class SuggestionLinks extends Component<{
    suggestionLink: ISuggestionLinks, isMobile: boolean, isTablet: boolean, 
    position: string, portal: string, culture: string, magicLink: string
}, ISuggestionLinksState> {

    constructor(props) {
        super(props);
        this.state = {
            isScrollable: false,
            showLeftChevron: false,
            showRightChevron: true
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    public componentDidMount(): void {
        if (this.props.isMobile || this.props.isTablet) {
            const columnElement = document.querySelector(`.fas_suggestion_links.fas_single_row.fas_${this.props.position}`);
            if (columnElement) {
                const linkContainer = columnElement.querySelector('.fas_link_container');
                if (linkContainer.scrollWidth > linkContainer.clientWidth) this.setState({isScrollable: true});
            }
        }
    }

    public render() {
        return (
            <div className={classNames(
                styles.suggestion_links,
                {[styles.single_row]: this.props.isMobile || this.props.isTablet},
                {[styles.top]: this.props.position === 'top'},
                {[styles.bottom]: this.props.position === 'bottom'},
                this.props.suggestionLink.style
            )}>
                <div className={styles.label}
                    dangerouslySetInnerHTML={{__html: `$$$${this.props.suggestionLink.labelLocalizationKey}$$$`}}>
                </div>

                <div className={classNames(styles.link_container, {[styles.scrollable]: this.state.isScrollable})}
                    onScroll={this.handleScroll}>
                    {this.state.isScrollable &&
                        <div className={classNames(styles.chevron_left, {[styles.hidden]: !this.state.showLeftChevron})}>
                            <ArrowDownIcn />
                        </div>
                    }
                    {this.props.suggestionLink.suggestionLinks.map((link, index) => {
                        link.portal = this.props.portal;
                        link.culture = this.props.culture;
                        return <SuggestionLink suggestionLink={link} key={index} magicLink={this.props.magicLink}/>
                    })}
                    {this.state.isScrollable &&
                        <div className={classNames(styles.chevron_right, {[styles.hidden]: !this.state.showRightChevron})}>
                            <ArrowDownIcn />
                        </div>
                    }
                </div>
            </div>
        )
    }

    private handleScroll(event): void {
        const target = event.target as HTMLDivElement;
        if (target.scrollLeft === 0 ) {
            if (this.state.showLeftChevron) this.setState({showLeftChevron: false});
            if (!this.state.showRightChevron) this.setState({showRightChevron: true});
        } else if (target.scrollWidth - target.scrollLeft === target.clientWidth) {
            if (!this.state.showLeftChevron) this.setState({showLeftChevron: true});
            if (this.state.showRightChevron) this.setState({showRightChevron: false});
        } else if (target.scrollLeft > 0) {
            if (!this.state.showLeftChevron) this.setState({showLeftChevron: true});
            if (!this.state.showRightChevron) this.setState({showRightChevron: true});
        }
    }
}