/* eslint-disable max-len */
import React, { Component } from 'react';
import ArticleTileGrid from '../articleTileGrid/article-tile-grid';
import classNames from 'classnames';
import BottomSearchbox from './searchbox/bottom-searchbox';
import FasBar from '../fas-bar/fas-bar';
import LanguageSwitcher from './language-switcher/language-switcher';
import SuggestionLinks from './suggestion-links/suggestion-links';
import TeaserSuggestions from './teaser-suggestions/teaser-suggestions';
import TopSearchbox from './searchbox/top-searchbox';
import '../../Common/customStyles.scss';
import styles from './searchresultpage.scss';
import StickyHeader from '../stickyHeader/stickyHeader';
import Paging from '../paging/paging';
import { ArticleTilesAjaxCaller } from '../articleTileGrid/ArticleTilesAjaxCaller';
import { IFilteringResult } from '../articleTileGrid/article-tile/article-tile.d';
import {
    IMetaEventForSearchResult,
    ISearchResultPageProps,
    ISearchResultPageState,
    SearchResultPagePropsToSdvMapper,
    SearchResultPageType,
} from './searchresultpage.d';
import { GlobalState } from '../globalState/globalState';
import { ArticleListController } from '../globalState/ArticleListController';
import { convertFromServiceToReactWorld } from '../globalState/ServiceSelectedFilterConverter';
import MainSidePanel from '../mainSidePanel/mainSidePanel';
import * as helper from '../../Common/html-helper';
import ProgressBarAnimation from '../progressBarAnimation/progressBarAnimation';
import TouchPointFactory from '../../Common/MPCTouchPointFactory';
import { BookmarkController } from '../globalState/BookmarkController';
import { ScrollLock } from '../../../Helper/scrollLock';
import SortContextProvider from '../globalState/sortContextProvider';
import * as Constants from '../../Common/constants';
import * as MetaEvents from './metaEventForSearch.js'

export default class SearchResultPage extends Component<ISearchResultPageProps, ISearchResultPageState> {
    private articleTilesAjaxCaller: ArticleTilesAjaxCaller;
    private gridRef = React.createRef<HTMLDivElement>();
    private touchPointFactory: TouchPointFactory;
    private globalState: GlobalState;
    private bookmarkController: BookmarkController;
    infinityScrollingIsLoading: boolean;
    private searchRef = React.createRef<HTMLDivElement>();

    constructor(props: ISearchResultPageProps) {
        super(props);
        this.state = {
            salesDesignationViews: SearchResultPagePropsToSdvMapper(this.props),
            pageNo: this.props.searchResultPageProps.pagingInformation?.pageNumber ?? 1,
            waitingForResponse: false,
            totalResultCount: this.props.searchResultPageProps.pagingInformation?.totalResultCount ?? 0,
            totalPageCount: this.props.searchResultPageProps.pagingInformation?.totalPageCount ?? 1,
            isBreadcrumbOnly: false,
            componentMounted: false,
            scrollPosition: 0,
            activeDropDownFilterName: '',
            stickyHeaderDropDownFilterName: '',
            isStickyHeaderVisible: false,
            toggleToUpdateFasBar: false,
            isTeaserViewResultPage: this.props.searchResultPageProps.articleLists?.length >= 1 && 
                this.props.searchResultPageProps.type === SearchResultPageType.TeaserAlternativeView,
            isEmptySearchResultPage: this.props.searchResultPageProps.type === SearchResultPageType.Empty,
            infiniteScrollUpMode: helper.isUrlWithPageQuery(this.props.searchResultPageProps.url),
            pageEndOffset: this.props.searchResultPageProps.pagingInformation?.pageEndOffset ?? 0,
            infiniteScrollUpMinPageNo: this.props.searchResultPageProps.pagingInformation?.pageNumber ?? 1,
            isSearchBottomReached: false
        };

        // init global state
        const initSelectedFilters = convertFromServiceToReactWorld(
            props.searchResultPageProps.filterContentViewModel.userSelectedFilters,
            props.searchResultPageProps.filterContentViewModel.filterModel);
        this.globalState = new GlobalState(
            this.props.searchResultPageProps.filterContentViewModel.filterModel,
            initSelectedFilters,
            this.props.searchResultPageProps.filterContentViewModel.seoSlug,
            this.props.searchResultPageProps.filterContentViewModel.searchTerm,
            this.props.searchResultPageProps.filterContentViewModel.kiosk,
            this.props.searchResultPageProps.magicLink);
        this.articleTilesAjaxCaller = new ArticleTilesAjaxCaller(this.globalState.Spinner, this.props.searchResultPageProps.magicLink);
        const articleListController = new ArticleListController(
            this.articleTilesAjaxCaller, this.globalState.onNewArticlesLoaded);
        this.globalState.articleListController = articleListController;
        this.onNewArticles = this.onNewArticles.bind(this);
        this.globalState.registerOnArticlesChanged(this.onNewArticles);

        const salesDesignationViews = SearchResultPagePropsToSdvMapper(this.props);
        if (salesDesignationViews.length > 0)
            this.bookmarkController = new BookmarkController(salesDesignationViews, this.globalState);

        this.touchPointFactory = TouchPointFactory.instance;
        this.props.searchResultPageProps.filterContentViewModel.l10n = this.props.l10n;
        this.props.searchResultPageProps.filterContentViewModel.originalSearchTerm =
            this.props.searchResultPageProps.originalSearchTerm;
        if (this.props.searchResultPageProps.type === SearchResultPageType.FullAlternativeView) {
            this.props.searchResultPageProps.filterContentViewModel.searchTerm =
                this.props.searchResultPageProps.articleLists[0].searchString;
        }

        this.handleLoadArticles = this.handleLoadArticles.bind(this);
        this.stickyHeaderVisibleChange = this.stickyHeaderVisibleChange.bind(this);
        this.handleActiveDropDownFilterChange = this.handleActiveDropDownFilterChange.bind(this);
        this.handleSortContextChange = this.handleSortContextChange.bind(this);
        this.infiniteScroll = this.infiniteScroll.bind(this);
        this.registerMpc = this.registerMpc.bind(this);

        if (typeof document !== 'undefined') {
            document.addEventListener('DOMContentLoaded', this.registerMpc);
        }
    }

    private registerMpc(): void{
        // first iteration for mpc tp on after page is loaded
        this.touchPointFactory.createTouchPointsBatch(this.state.salesDesignationViews);
    }

    componentDidMount(): void {
        this.setState({ isBreadcrumbOnly: false, componentMounted: true });

        if(window && window.location && window.location.hash.startsWith('#ats-')) {
            const tile = window.document.body.querySelector(window.location.hash);
            if(tile)
                tile.scrollIntoView({behavior:'auto', block:'center', inline:'center'})
            //remove hash
            history.replaceState(null, null, window.location.pathname + window.location.search);
        }
        if(window && window.location && window.location.search.indexOf('page=') >= 0) {
            const params = new URL(window.location.href).searchParams;
            params.delete('page');
            let newUrl = window.location.pathname;
            if (params.size > 0) {
                newUrl += '?' + params.toString();
            }
            history.replaceState(null, null, newUrl);
        }
        this.handleMetaEvent();
    }

    private infiniteScroll() {
        if(
            this.state.infiniteScrollUpMode && // we have returned from somwhere to ATS
            this.state.infiniteScrollUpMinPageNo > 1 && // there are missing pages still
            !this.infinityScrollingIsLoading // we dont currently wait for an older load
        ) {
            const lastScrollHeight = window.document.body.scrollHeight;
            this.infinityScrollingIsLoading = true;
            this.articleTilesAjaxCaller.loadMoreArticles(
                this.state.infiniteScrollUpMinPageNo - 1, 
                this.props.searchResultPageProps.filterContentViewModel.kiosk, 
                this.props.searchResultPageProps.filterContentViewModel.viewType,
                false
            ).then((data) => {
                this.infinityScrollingIsLoading = false;
                this.setState({
                    salesDesignationViews: data.articles.concat(this.state.salesDesignationViews), //prepend
                    infiniteScrollUpMinPageNo: this.state.infiniteScrollUpMinPageNo - 1,
                }, () => {
                     // we want to keep the scroll position
                     setTimeout(()=>{
                        const heightDifference = window.document.body.scrollHeight - lastScrollHeight;
                        window.scrollBy({top: heightDifference});
                    }, 100);
                });
            }).catch(()=>{
                this.infinityScrollingIsLoading = false;
            });
        }
    }

    componentWillUnmount(): void {
        if (typeof document !== 'undefined') {
            document.removeEventListener('DOMContentLoaded', this.registerMpc);
        }
    }

    public onNewArticles(result: IFilteringResult) {
        this.setState({
            salesDesignationViews: result.articles,
            totalResultCount: result.totalResultCount,
            totalPageCount: result.totalPageCount,
            pageEndOffset: result.pageEndOffset,
            pageNo: 1,
            waitingForResponse: false,
            toggleToUpdateFasBar: !this.state.toggleToUpdateFasBar,
            infiniteScrollUpMode: false // after filtering, page url parameter related element should be removed
        });
        ScrollLock.instance.checkSliderPosition();
    }

    public stickyHeaderVisibleChange(visible: boolean) {
        if (this.state.isStickyHeaderVisible !== visible) {
            const activeFilterDropdownName = this.state.isStickyHeaderVisible ?
                this.state.stickyHeaderDropDownFilterName : this.state.activeDropDownFilterName;
            this.setState({ stickyHeaderDropDownFilterName: visible ? activeFilterDropdownName : '' });
            this.setState({ activeDropDownFilterName: visible ? '' : activeFilterDropdownName });
        }
        this.setState({ isStickyHeaderVisible: visible });
           }

    public handleActiveDropDownFilterChange = (prActiveDropDownFilter: string) => {
        this.setState({
            activeDropDownFilterName: this.state.isStickyHeaderVisible ? '' : prActiveDropDownFilter,
            stickyHeaderDropDownFilterName: this.state.isStickyHeaderVisible ? prActiveDropDownFilter : ''
        });
    }
    

    private handleSortContextChange(shouldForceScroll) {
        this.globalState.updateArticleList(shouldForceScroll);
    }

    public render() {
        const isMspNeeded = this.props.searchResultPageProps.articleLists?.length === 1 &&
            this.props.searchResultPageProps.type !== SearchResultPageType.TeaserAlternativeView &&
            this.state.componentMounted;
        const shouldHaveFasBar = this.props.searchResultPageProps.articleLists?.length === 1 && (
            this.props.searchResultPageProps.filterContentViewModel?.filterModel &&
            this.state.totalResultCount > 0);
        const isNewsLetterVisible = !(this.props.searchResultPageProps.filterContentViewModel.kiosk ||
            this.props.searchResultPageProps.filterContentViewModel.isMobile) 
            && this.state.isStickyHeaderVisible === true && this.state.salesDesignationViews.length > 4;
        const renderNLP = !this.props.searchResultPageProps.filterContentViewModel.isMobile &&
            this.props.searchResultPageProps.type !== SearchResultPageType.Empty &&
            this.props.searchResultPageProps.type !== SearchResultPageType.TeaserAlternativeView;

        return (
            <SortContextProvider
                viewType={this.props.searchResultPageProps.filterContentViewModel.viewType}
                l10n={this.props.searchResultPageProps.filterContentViewModel.l10n}
                glovesFilter={this.globalState.filterModel
                    .filters?.find((x) => x.name === Constants.GLOVES)}
                initialSort={this.props.searchResultPageProps.filterContentViewModel.initialSort}
                updateArticleList={this.handleSortContextChange}
                magicLink={this.props.searchResultPageProps.magicLink}
            >
                <div ref={this.searchRef} className={classNames(styles.searchresultpage, this.props.searchResultPageProps.style)}>
                    {isMspNeeded &&
                        <MainSidePanel
                            shouldShowProductFinder={false}
                            productFinderServicesUpAndRunning={false}
                            filterContentViewModel={this.props.searchResultPageProps.filterContentViewModel}
                            globalState={this.globalState}
                            l10n={this.props.l10n}
                            productFinderKey={''}
                        />
                    }

                    {(shouldHaveFasBar &&
                        <>
                            <div id='ats_scope-fas-bar' className='fas_scope-fas'>
                                <FasBar
                                    {...this.props.searchResultPageProps.filterContentViewModel}
                                    dropDownFilterChange={this.handleActiveDropDownFilterChange}
                                    activeDropDownFilterName={this.state.activeDropDownFilterName}
                                    globalState={this.globalState}
                                    toggleToUpdateFasBar={this.state.toggleToUpdateFasBar}
                                />
                                <ProgressBarAnimation
                                    scrollPosition={this.state.scrollPosition}
                                    pageEndOffset={this.state.pageEndOffset}
                                    salesDesignationViews={this.state.salesDesignationViews}
                                    totalResultCount={this.state.totalResultCount}
                                    infiniteScroll={this.infiniteScroll}
                                />
                            </div>

                            <StickyHeader
                                isBreadcrumbOnly={this.state.isBreadcrumbOnly}
                                isGlobal={this.props.searchResultPageProps.filterContentViewModel.isGlobal}
                                isEsApp={this.props.searchResultPageProps.filterContentViewModel.isEsApp}
                                gridRef={this.gridRef}
                                visibleCallBack={this.stickyHeaderVisibleChange}
                                renderNLP={renderNLP}
                                nlpIsVisibleByParentRules={isNewsLetterVisible}
                            >
                                <div id='ats_sticky-fas-bar' className='fas_scope-fas'>
                                    <FasBar
                                        {...this.props.searchResultPageProps.filterContentViewModel}
                                        dropDownFilterChange={this.handleActiveDropDownFilterChange}
                                        activeDropDownFilterName={this.state.stickyHeaderDropDownFilterName}
                                        globalState={this.globalState}
                                        toggleToUpdateFasBar={this.state.toggleToUpdateFasBar}
                                    />
                                    <ProgressBarAnimation
                                        scrollPosition={this.state.scrollPosition}
                                        pageEndOffset={this.state.pageEndOffset}
                                        salesDesignationViews={this.state.salesDesignationViews}
                                        totalResultCount={this.state.totalResultCount}
                                        infiniteScroll={this.infiniteScroll}
                                    />
                                </div>
                            </StickyHeader>
                        </>
                    )}

                    {this.props.searchResultPageProps.topPageSearchBoxFragment &&
                        <TopSearchbox
                            l10n={this.props.l10n}
                            {...this.props.searchResultPageProps.topPageSearchBoxFragment}
                        />
                    }

                    {this.props.searchResultPageProps.topSuggestionLinks?.suggestionLinks.length > 0 &&
                        <SuggestionLinks
                            suggestionLink={this.props.searchResultPageProps.topSuggestionLinks}
                            isMobile={this.props.searchResultPageProps.filterContentViewModel.isMobile}
                            isTablet={this.props.searchResultPageProps.filterContentViewModel.isTablet}
                            portal={this.props.searchResultPageProps.filterContentViewModel.portal}
                            culture={this.props.searchResultPageProps.filterContentViewModel.culture}
                            position={'top'}
                            magicLink={this.props.searchResultPageProps.magicLink}
                        />
                    }
               
                {this.props.searchResultPageProps.articleLists?.length === 1
                    && this.props.searchResultPageProps.type !== SearchResultPageType.TeaserAlternativeView && (
                        <>
                            <ArticleTileGrid
                                currencyInformation={this.props.searchResultPageProps.currencyInformation}
                                viewType={this.props.searchResultPageProps.filterContentViewModel.viewType}
                                isMobile={this.props.searchResultPageProps.filterContentViewModel.isMobile}
                                isGlobal={this.props.searchResultPageProps.filterContentViewModel.isGlobal}
                                culture={this.props.searchResultPageProps.filterContentViewModel.culture}
                                portal={this.props.searchResultPageProps.filterContentViewModel.portal}
                                isFilterDropDownOpen={!!this.state.activeDropDownFilterName}
                                articles={this.state.salesDesignationViews}
                                category={null}
                                l10n={this.props.l10n}
                                kiosk={this.props.searchResultPageProps.filterContentViewModel.kiosk}
                                gridRef={this.gridRef}
                                addItemOriginToPdpLink={true}
                                gridNumber={0}
                                articleTilesAjaxCaller={this.articleTilesAjaxCaller}
                                globalState={this.globalState}
                                singleArticleView={this.state.salesDesignationViews && this.state.salesDesignationViews.length === 1}
                                magicLink={this.props.searchResultPageProps.magicLink}
                            />
                            <Paging
                                viewedArticleNumber={this.state.pageEndOffset}
                                totalArticleCount={this.state.totalResultCount}
                                waitingForResponse={this.state.waitingForResponse}
                                onLoadMore={this.handleLoadArticles}
                                l10n={this.props.l10n}
                                currentPageNumber={this.state.pageNo}
                                totalPagesNumber={this.state.totalPageCount}
                                url={this.props.searchResultPageProps.url}
                                showHiddenLinks={false}
                            />
                        </>
                    )
                }

                    {this.state.isTeaserViewResultPage &&
                        <TeaserSuggestions
                            articleLists={this.props.searchResultPageProps.articleLists}
                            currencyInformation={this.props.searchResultPageProps.currencyInformation}
                            kiosk={this.props.searchResultPageProps.filterContentViewModel.kiosk}
                            isMobile={this.props.searchResultPageProps.filterContentViewModel.isMobile}
                            portal={this.props.searchResultPageProps.filterContentViewModel.portal}
                            culture={this.props.searchResultPageProps.filterContentViewModel.culture}
                            isGlobal={this.props.searchResultPageProps.filterContentViewModel.isGlobal}
                            l10n={this.props.l10n}
                            globalState={this.globalState}
                            articleTilesAjaxCaller={this.articleTilesAjaxCaller}
                            magicLink={this.props.searchResultPageProps.magicLink}
                        />
                    }

                    {this.props.searchResultPageProps.bottomSuggestionLinks?.suggestionLinks.length > 0 ? (
                        <SuggestionLinks
                            suggestionLink={this.props.searchResultPageProps.bottomSuggestionLinks}
                            isMobile={this.props.searchResultPageProps.filterContentViewModel.isMobile}
                            isTablet={this.props.searchResultPageProps.filterContentViewModel.isTablet}
                            portal={this.props.searchResultPageProps.filterContentViewModel.portal}
                            culture={this.props.searchResultPageProps.filterContentViewModel.culture}
                            position={'bottom'}
                            magicLink={this.props.searchResultPageProps.magicLink}
                        />
                    ) : this.props.searchResultPageProps.topSuggestionLinks?.suggestionLinks.length > 0 && (
                        <SuggestionLinks
                            suggestionLink={this.props.searchResultPageProps.topSuggestionLinks}
                            isMobile={this.props.searchResultPageProps.filterContentViewModel.isMobile}
                            isTablet={this.props.searchResultPageProps.filterContentViewModel.isTablet}
                            portal={this.props.searchResultPageProps.filterContentViewModel.portal}
                            culture={this.props.searchResultPageProps.filterContentViewModel.culture}
                            position={'bottom'}
                            magicLink={this.props.searchResultPageProps.magicLink}
                        />
                    )}

                    {!this.state.isEmptySearchResultPage &&
                        <BottomSearchbox
                            style={this.props.searchResultPageProps.style}
                            l10n={this.props.l10n}
                        />
                    }

                    {this.props.searchResultPageProps.languageSwitcherFragment &&
                        <LanguageSwitcher
                            languageSwitcherProps={this.props.searchResultPageProps.languageSwitcherFragment}
                            l10n={this.props.l10n}
                            magicLink={this.props.searchResultPageProps.magicLink}
                        />
                    }
                </div>
            </SortContextProvider>
        );
    }

    private handleLoadArticles() {
        this.setState({ waitingForResponse: true })
        this.articleTilesAjaxCaller
            .loadMoreArticles(
                this.state.pageNo + 1,
                this.props.searchResultPageProps.filterContentViewModel.kiosk,
                this.props.searchResultPageProps.filterContentViewModel.viewType,
                true
            )
            .then((data) => {
                if (data && Array.isArray(data.articles)) {
                    this.setState({
                        pageNo: this.state.pageNo + 1,
                        pageEndOffset: data.pageEndOffset,
                        salesDesignationViews: this.state.salesDesignationViews.concat(data.articles)
                    }, () => {
                        const scrollPos = helper.calculateScrollDistance(
                            this.state.pageEndOffset, 
                            this.state.salesDesignationViews);
                        this.setState({ scrollPosition: scrollPos });
                        this.bookmarkController.updateArticleList(data.articles);

                        // call mpc for tp upgrade
                        this.touchPointFactory.createTouchPointsBatch(data.articles);
                    });
                }
                this.setState({ waitingForResponse: false })
            });
    }

    private handleMetaEvent() {
        if (this.props.searchResultPageProps.type === SearchResultPageType.TeaserAlternativeView) {
            const contents: IMetaEventForSearchResult[] = [];
            this.props.searchResultPageProps.articleLists.map(list => {
                contents.push({
                    search_string: list.searchString.toLowerCase(),
                    content_ids: list.articles.map(
                        (view) => view.defaultSalesArticleNo
                    ),
                });
            });
            if (contents.length > 1) MetaEvents.sendEventForTeaserSearchResult(contents);
        } else if (!this.state.isEmptySearchResultPage) {
            const salesArticleNos = this.state.salesDesignationViews.map(view => view.defaultSalesArticleNo);
            const searchString = this.props.searchResultPageProps.articleLists[0].searchString.toLowerCase();
            MetaEvents.sendEventForSingleSearchResult(searchString, salesArticleNos);
        }
    }
}
